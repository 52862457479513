*{
    margin: 0px;
    padding: 0px;

}

.Navbar-items{
    display: flex;
    justify-content: space-around;
    align-items: center ;
    padding: 10px; ;
    box-shadow: 0 5px 15px 0;
    width: 100%;
    height: 80px;
    background-color: white;
    position: fixed;
    z-index: 999;
}
.navbar-logo{
    color: blue;
    font-size: 3rem;
    cursor: pointer;
    font-style: italic;
}
.nav-menu{
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-gap: 15px;
    list-style: none;
    align-items: center;
}
.get-in{
    display: grid;
    grid-template-columns: repeat(7,auto);
    grid-gap: 0;
    list-style: none;
    align-items: center;

}

.log{
    color: black;
    text-decoration:none;
}
.log:hover{
    color: blue;
    transition: all 0.2s ease-in-out;
}

.search-box{
    width: fit-content;
    height: fit-content;
    position: relative;
}
.input-search{
    height: 50px;
    width: 50px;
    border-style: none;
    padding: 10px;
    font-size: 18px;
    letter-spacing: 2px;
    outline: none;
    border-radius: 25px;
    cursor: pointer;
    transition: all .5s ease-in-out;
    background-color:transparent;
    padding-right: 40px;
    color:#000000;
}
.input-search::placeholder{
    color:rgba(0, 0, 0, 0.5);
    font-size: 18px;
    letter-spacing: 2px;
    font-weight: 100;
}
.btn-search{
    width: 50px;
    height: 50px;
    border-style: none;
    font-size: 20px;
    font-weight: bold;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    color:blue ;
    background-color:transparent;
    pointer-events: painted;  

}
.btn-search:focus ~ .input-search{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(0, 0, 0, 0.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.input-search:focus{
    width: 300px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom:1px solid rgba(0, 0, 0, 0.5);
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
}
.active{
    color: blue;
}