@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

* {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
}
.heading{
	text-align: center;
    font-size: 300%;
	font-family: cursive;
	color: brown;
	padding-top: 100px ;
	padding-bottom: 25px;
}
.hotel-container {
	display: flex;
	justify-content: center;
	align-items: center;
}
.main-container {
	display: flex;
	width: 270px;
	height: 540px;
	position: relative;
	margin: 0 20px;
}

.poster-container {
	display: flex;
	width: 230px;
	position: absolute;
	top: 0;
	left: 20px;
	z-index: 1;
}

.poster {
	display: flex;
	width: 100%;
	box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.6);
}

.booking-container {
	display: flex;
	background: #fff6df;
	width: 270px;
	height: 520px;
	flex-direction: column;
	align-items: center;
	border-radius: 5px;
	position: absolute;
	top: 20px;
	box-shadow: 0 5px 20px 3px rgba(0, 0, 0, 0.6);
	opacity: 0;
}

.main-container:hover .booking-container {
	display: flex;
	opacity: 1;
	animation: bounceIn 0.6s linear;
}

@keyframes bounceIn {
	0%,
	20%,
	40%,
	60%,
	80%,
	to {
		-webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
		animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
	}
	0% {
		opacity: 0;
		-webkit-transform: scale3d(0.3, 0.3, 0.3);
		transform: scale3d(0.3, 0.3, 0.3);
	}
	20% {
		-webkit-transform: scale3d(1.03, 1.03, 1.03);
		transform: scale3d(1.03, 1.03, 1.03);
	}
	40% {
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}
	60% {
		opacity: 1;
		-webkit-transform: scale3d(1.01, 1.01, 1.01);
		transform: scale3d(1.01, 1.01, 1.01);
	}
	80% {
		-webkit-transform: scale3d(0.97, 0.97, 0.97);
		transform: scale3d(0.97, 0.97, 0.97);
	}
	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
	}
}


.booking__content {
	width: 100%;
	position: absolute;
	bottom: 0;
	text-align: center;
}

.booking__hotel-name {
	text-transform: uppercase;
	margin-bottom: 5px;
}

.booking__hotel-details {
	color: #999;
	font-size: 0.9rem;
	margin-bottom: 20px;
}

.booking__current-price {
	color: #69c982;
	font-size: 1.4rem;
	font-weight: bold;
}

.booking__old-price {
	color: #999;
	text-decoration: line-through;
	margin-bottom: 10px;
}

.booking__buy-btn {
	cursor: pointer;
	width: 100%;
	background: #2f2f2f;
	color: white;
	padding: 15px 0;
	font-size: 1rem;
	font-weight: bold;
	text-transform: uppercase;
	border: 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
}

.disabled {
	color: grey;
  }