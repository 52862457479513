* {
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
}

.main{
    padding-top: 150px;
}
.container-menu{
    margin: 20px auto;
    max-width: 90%;
    border-radius: 10px;
    background: white;
    box-shadow: 0px 0px 20px black;
}

.inside-container{
    display: flex;
    flex-direction: row;
}

.main-main-menu{
    padding-top: 40px;
    width: 50%;
}
.menu-head{
    text-align: center;
}

.main-menu{
    display: flex;
    flex-direction: row;
    padding: 10px 40px;
}


.Breakfast_Combos{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.amount{
    display: flex;
    flex-direction: column;
}

.text-menu{
    padding: 10px;
}


.button_menu{
    width: 20%;
    margin-top: 40px;
    margin-bottom: 20px;
    margin-left: 75%;
    background-color: #219ebc;
    color: #000000;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 5px rgba(8,7,16,0.6);
  }
  
  .button_menu:hover{
    background-color: #8ecae6;
  }


  .button_menu[disabled] {
    /* Styles for the disabled state */
    background-color: #8ecae6; /* Light gray */
    cursor: not-allowed;
  }
  
  .button_menu[disabled]:hover {
    /* Override hover effect for disabled state */
    background-color: #8ecae6; /* Light gray */
}

