* {
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

.container-content {
    padding: 20px 20px;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    border-radius: 10px;
}

.heading-contact {
    margin: 20px auto;
    width: 20%;
}
.heading-feed {
    margin: 10px auto;
    width: 20%;

}
.feed-address{
    display: grid;
    grid-template-columns: repeat(2, auto);
}

.feedInfo{
    display: grid;
    grid-template-rows: repeat(2, auto);
    padding: 20px 20px;
    gap: 20px;
}

.contactInfo{
    margin-top: 40px;
    width: 90%;
}
.box-one{
    margin-top: 20px;
}

.feed{
    height: 40px;
    font-size: x-large;
}
.info{
    height: 100px;
    font-size: x-large;
    resize: none;
}


.feedback-comment {
    height: 1000px;
    list-style-type: none;
    scrollbar-width: none;
    overflow-y: scroll;
    border-radius: 10px;
}

.comment {
    margin: 20px auto;
    max-width: 95%;
    padding: 10px;
    border: 1px solid black;
    border-radius: 0px 100px 100px 100px;
}

.font-comment{
    font-family: 'Times New Roman', Times, serif;
    margin: 10px auto;
    padding-left: 35px;
}

.font-user{
    font-family: 'Times New Roman', Times, serif;
    margin: 10px auto;
    padding-left: 10px;
}
.font-time{
    font-family: 'Times New Roman', Times, serif;
    font-size: medium;
    float: right;
    margin: 10px 20px;    
}   

.send-feed{
    color: #000;
}
.button_feed{
    width: 20%;
    margin-bottom: 10px;
    margin-left: 75%;
    background-color: #219ebc;
    color: #000000;
    padding: 15px 0;
    font-size: 18px;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 5px rgba(8,7,16,0.6);
  }
  
  .button_feed:hover{
    background-color: #8ecae6;
  }
