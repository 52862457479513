@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap");

.container {
    display: grid;
    grid-template-columns: repeat(2, auto);
    padding: 80px 20px 40px;
    grid-gap: 10;
    margin: 20px auto;
    max-width: 90%;

}

.branch-container {
    width: 35vh;
    border-radius: 10px;
    box-shadow: 1px 1px 20px rgba(8, 7, 16, 0.6);
}

.details-container {
    width: 150vh;
}

.b {
    padding-bottom: 15px;
}

.branch {
    padding: 25px;
    justify-content: center;
    align-items: center;
}

.nav {
    display: flex;
    flex-direction: column;
    /* Arrange items vertically */
}

.branch_sub {
    text-decoration: none;
    color: black;
    margin: 15px;
    font-weight: bold;
}

.active {
    color: #007bff;
    /* Change the color to whatever you prefer */
    font-weight: bold;
    /* Make the text bold */
}

h1 {
    padding: 10px;
}

.disabled {
    background-color: gray;
    cursor: not-allowed;
    /* Change cursor for disabled seats */
    border: 3px solid gray;
    /* Optionally, you can adjust border style */
    color: black;
    opacity: 0.5;
    filter: blur(1px);
}

.desc {
    padding-left: 15px;
    font-size: larger;
    padding-right: 10px;
}


.buttonIn {
    width: 300px;
    position: relative;
}

.seats-inbox {
    padding-left: 10px;
    text-align: center;
    width: 15%;
    outline: none;
    height: 30px;
    border-radius: 5px;
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.no-inbox {
    width: 60%;
    outline: none;
    height: 30px;
    border-radius: 5px;
    font-size: large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.seat-button {
    border-radius: 5px;
    margin-top: 30px;
    padding: 5px;
    border: none;
    height: 40px;
    cursor: pointer;
    color: white;
    background-color: #1e90ff;
}


.want {
    font-size: x-large;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 15px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.bold {
    font-size: large;
    padding-top: 10px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.text-center {
    font-size: large;
    padding-top: 10px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}


.loc {
    width: 90%;
    margin: 0px 60px;
    font-size: large;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}


.available {
    padding-top: 10px;
    font-size: medium;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.tablesetting {
    height: 77vh;
    width: 100%;
    cursor: pointer;
}


.active-link {
    color: blue;
}

.order-booking {
    width: fit-content;
    padding: 20px 20px;
}

.order-booking-four {
    width: fit-content;
    padding: 10px 20px;
}

.flex {
    display: flex;
}

.chair-left {
    width: 15px;
    height: 15px;
    background-color: #A1A1A8;
    margin: 12px 2px;
    border-radius: 0px 8px 8px 0px;
}

.chair-right {
    width: 15px;
    height: 15px;
    background-color: #A1A1A8;
    margin-top: 12px;
    margin-left: 2px;
    border-radius: 8px 0px 0px 8px;

}

.chair-right-two {
    width: 15px;
    height: 15px;
    background-color: #A1A1A8;
    margin-top: 1px;
    margin-left: 2px;
    border-radius: 8px 0px 0px 8px;

}

.chair-top {
    width: 15px;
    height: 15px;
    background-color: #A1A1A8;
    margin: 2px 5px;
    border-radius: 0px 0px 8px 8px;

}

.top-flex {
    display: flex;
    margin-left: 22px;
}

.bottom-flex {
    display: flex;
    margin-left: 23px;
}

.chair-bottom {
    width: 15px;
    height: 15px;
    background-color: #A1A1A8;
    margin: 2px 5px;
    border-radius: 8px 8px 0px 0px;

}

.table-eight {
    width: 80px;
    height: 40px;
    background-color: #B4B4B9;
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(8, 7, 16, 0.6);

}

.table-six {
    width: 58px;
    height: 40px;
    background-color: #B4B4B9;
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(8, 7, 16, 0.6);
}

.table-four {
    width: 33px;
    height: 40px;
    background-color: #B4B4B9;
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(8, 7, 16, 0.6);
}

.table-two {
    width: 25px;
    height: 25px;
    background-color: #B4B4B9;
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(8, 7, 16, 0.6);
}

.flex-two {
    display: flex;
    flex-direction: row;
    align-items: center;
}


.order-booking:hover {
    transform: scale(1.02);
}

.hotel-design {
    display: grid;
    place-content: center;
    perspective: 3200px;
    margin-bottom: 20px;
}

.face {
    height: 720px;
    width: 950px;
}

.back-face {
    background-color: lavender;
    border: 4rem ridge #6699CC;
    position: relative;

}

.back-face:after {
    position: absolute;
    top: -1.5rem;
    left: 50.9%;
    transform: translateX(-50%);
    content: '';
    background-color: mintcream;
    width: 60px;
    height: 1.5rem;
}

.four-wall {
    display: flex;
    flex-direction: row;
    border: 0.5rem #3D5A79;
    border-right-style: solid;
    border-bottom-style: solid;
    width: fit-content;
    position: relative;
}

.four-wall:after {
    position: absolute;
    right: -0.5rem;
    top: 48%;
    transform: translateY(-50%);
    content: '';
    background-color: lavender;
    width: 0.5rem;
    height: 30px;
}

.four-wall-down {
    display: flex;
    flex-direction: row;
    border: 0.5rem #3D5A79;
    border-right-style: solid;
    width: fit-content;
    position: relative;
}

.four-wall-down:after {
    position: absolute;
    right: -0.5rem;
    top: 48%;
    transform: translateY(-50%);
    content: '';
    background-color: lavender;
    width: 0.5rem;
    height: 30px;
}


.eight-one {
    display: flex;
    flex-direction: row;
    border: 0.5rem #3D5A79;
    border-right-style: solid;
    border-bottom-style: solid;
    width: fit-content;
    position: relative;
    gap: 10px;
}

.eight-one:after {
    position: absolute;
    bottom: -0.5rem;
    left: 52.5%;
    transform: translateX(-50%);
    content: '';
    background-color: lavender;
    width: 90px;
    height: 0.5rem;
}

.eight-two {
    display: flex;
    flex-direction: row;
    border: 0.5rem;
    border-left-style: solid;
    border-bottom-style: solid;
    border-left-color: #3D5A79;
    border-bottom-color: #6699CC;
    width: fit-content;
    position: relative;
    gap: 10px;
}

.eight-two:after {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    content: '';
    background-color: lavender;
    width: 80px;
    height: 0.5rem;
}

.eight-flex {
    display: flex;
    flex-direction: row;
    gap: 25px;

}

.wall {
    border: 0.5rem;
    border-right-style: solid;
    border-right-color: #6699CC;
}

.wall-one {
    border: 0.5rem;
    border-right-style: solid;
    border-right-color: #6699CC;
    padding-right: 15px;
}

.gate {
    background-color: lightseagreen;
    width: 65px;
    border-radius: 0px 0px 5px 5px;
}

.group-room {
    display: flex;
    flex-direction: row;
    gap: 75px;
}

.washroom {
    border: 0.5rem;
    width: 300px;
    border-right-style: solid;
    border-top-style: solid;
    border-top-color: #3D5A79;
    border-right-color: #6699CC;
    border-spacing: 15px;
    height: 100px;
    position: relative;
    text-align: center;
    padding-top: 25px;
}

.washroom:after {
    position: absolute;
    right: -0.5rem;
    top: 70%;
    transform: translateY(-50%);
    content: '';
    background-color: lavender;
    width: 0.5rem;
    height: 30px;
}

.kitchen {
    border: 0.5rem;
    width: 370px;
    border-left-style: solid;
    border-top-style: solid;
    border-top-color: #6699CC;
    border-left-color: #6699CC;
    height: 100px;
    position: relative;
    text-align: center;
    padding-top: 25px;
}

.kitchen:after {
    position: absolute;
    left: -0.5rem;
    top: 25%;
    transform: translateY(-50%);
    content: '';
    background-color: lavender;
    width: 0.5rem;
    height: 30px;
}

.middel-flex {
    display: flex;
    flex-direction: row;
}

.three-seat {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 25px;
}

.three-seat-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 55px;
}

.three-seat-center {
    height: fit-content;
}

.wall-wall {
    border: 0.4rem;
    border-style: solid;
    border-color: #6699CC;
    height: 300px;
}

.flex-for-two {
    margin-left: 30px;
}

.phone-sign {
    margin-top: 10px;
    width: 70%;
    margin-left: 17%;
}

.phone-sign-input {
    font-size: x-large;
    margin-bottom: 20px;
    width: 100%;
}

.send-phone-otp {
    display: flex;
    gap: 10px;

}

.send-phone-otp-button {
    border-radius: 5px;
    padding: 5px;
    border: none;
    cursor: pointer;
    background-color: #1e90ff;
    box-shadow: 0px 0px 8px rgba(8, 7, 16, 0.6);
}

.send-phone-otp-button:hover {
    background-color: lightgreen;
}


.send-phone-verify-button {
    margin-top: 30px;
    margin-bottom: 20px;
    border-radius: 5px;
    font-size: large;
    padding: 10px;
    border: none;
    cursor: pointer;
    background-color: #1e90ff;
    box-shadow: 0px 0px 8px rgba(8, 7, 16, 0.6);
}

.send-phone-verify-button:hover {
    background-color: lightgreen;
}



.send-phone-otp input {
    font-size: x-large;
    margin-left: 20px;
    width: 60%;
}

.fliter-time-date {
    display: flex;
    margin: 20px auto;
    max-width: 90%;
    justify-content: center;
    gap: 60px;
}

.calender {
    padding: 0px 60px;
    font-size: x-large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.combobox {
    padding: 0px 60px;
    font-size: x-large;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;

}

.book_button-seat {
    margin-top: 40px;
    margin-left: 75%;
    width: 20%;
    background-color: #219ebc;
    color: #000000;
    padding: 15px;
    font-size: medium;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
}

.book_button-seat.disabled {
    background-color: #A1A1A8;
    opacity: 1;
    cursor: not-allowed;
}
.book_button-seat.disabled:hover {
    background-color: #A1A1A8;
}

.book_button-seat:hover {
    background-color: #8ecae6;
}