.items{
    margin: 2% 10%;
    padding: 40px 40px;
    border: 2px solid #219ebc;
    border-style: dashed;
    border-radius: 5px;
    box-shadow: 0 0 5px #219ebc;
}

.font{
    font-size: large;
}

.cart{
    font-size: 3rem;
    text-align: center;
    padding-bottom: 50px;
}
.book_lable{
    padding: 10px;
}

.book_button{
    margin-top: 40px;
    margin-left: 20px;
    width: 20%;
    background-color: #219ebc;
    color: #000000;
    padding: 15px;
    font-size: l;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 5px rgba(8,7,16,0.6);
  }
  
.book_button:hover{
    background-color: #8ecae6;
}
.loading-circle {
  width: 100px;
  height: 100px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center elements vertically */
  align-items: center; /* Center elements horizontally */
  z-index: 9999; /* Ensure the overlay is on top of other elements */
}

.loading-text {
  margin-top: 20px;
  font-size: 20px;
  color: #3498db;
  text-align: center; /* Center the text */
}
