
.fliter{
    display: flex;
    flex-direction: row;
    margin: 20px auto;
    max-width: 90%;
    gap:20px;

}

.search-bar{
    width:100%;
}

.order-cards{
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(8,7,16,0.6);
}

.orderS{
    padding-bottom: 20px;
}

.order-details{
    padding-top: 20px;
    padding-bottom: 20px;
}

.order-recipt {
    display: flex;
    list-style: none;
    margin: 20px auto;
    max-width: 90%;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0;
  }

  .flex-item {
    flex: 0 0 50%;
    padding: 10px;
    box-sizing: border-box;
  }

  .flex-items {
    flex: 0 0 50%;
    padding: 10px;
    box-sizing: border-box;
    color: red;
  }
.order-time{
    text-align: right;
    margin: 10px 10px 0px 0px;
    font-size: small;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.order-info{
    padding: 2px;
    font-size:large;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
}

.d-row{
    display: flex;
    flex-direction: row;
}

.order-done{
    width: 15%;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 48%;
    background-color: #219ebc;
    color: #000000;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 550;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 4px rgba(8,7,16,0.6);
  }
  
  .order-done:hover{
    background-color: #8ecae6;
  }
.search_all{
    width: 10%;
    background-color: #219ebc;
    color: #000000;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 550;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 4px rgba(8,7,16,0.6);   
}

.search_today{
    width: 15%;
    background-color: #219ebc;
    color: #000000;
    padding: 5px 0;
    font-size: 18px;
    font-weight: 550;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255,255,255,0.1);
    box-shadow: 0 0 4px rgba(8,7,16,0.6);   
}
.select_rest_branch{
    margin-left: 27%;
    margin-top: 15%;
    color: darkblue;
}
.msg{
    font-size: large;
    color: darkblue;
    margin-top: 15%;
    margin-left: 38%;
}