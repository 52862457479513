* {
  padding: 0;
  margin: 0;
}

body {
  background-color: white;
}

.grid {
  display: grid;
  grid-template-columns: repeat(7, auto);
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.center-reg {
  display: block;
  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}

.box-image {
  height: 520px;
  width: 400px;
  background-color: #219ebc;
  position: absolute;
  transform: translate(-100%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid #219ebc;
  box-shadow: -30px 0px 40px rgba(8, 7, 16, 0.6);
}

.box-image-reg {
  height: 630px;
  width: 400px;
  background-color: #219ebc;
  position: absolute;
  transform: translate(-100%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 10px 0px 0px 10px;
  border: 2px solid #219ebc;
  box-shadow: -30px 0px 40px rgba(8, 7, 16, 0.6);
}

.box {
  height: 520px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 0px 10px 10px 0px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 30px 0px 40px rgba(8, 7, 16, 0.6);
  padding: 30px 35px;
}

.box-reg {
  height: 630px;
  width: 400px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transform: translate(0%, -50%);
  top: 50%;
  left: 50%;
  border-radius: 0px 10px 10px 0px;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 30px 0px 40px rgba(8, 7, 16, 0.6);
  padding: 15px 35px;
}

.label {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
}

.label-reg {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: 500;
}

.input {
  display: block;
  height: 40px;
  width: 100%;
  background-color: rgba(255, 253, 253, 0.76);
  outline: auto;
  border-radius: 3px;
  border: none;
  outline-color: #219ebc;
  padding: 0 10px;
  margin-top: 8px;
  font-size: 14px;
  font-weight: 300;
}

::placeholder {
  color: #e5e5e5;
}

.button {
  margin-top: 40px;
  width: 100%;
  background-color: #219ebc;
  color: #000000;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
}

.button:hover {
  background-color: #8ecae6;
}

.button-reg {
  margin-top: 35px;
  width: 100%;
  background-color: #219ebc;
  color: #000000;
  padding: 15px 0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
}

.button-reg:hover {
  background-color: #8ecae6;
}

.extra {
  padding: 15px;
  display: grid;
  grid-template-rows: repeat(7, auto);
  text-align: center;
  grid-gap: 5px;
}

.extra-reg {
  padding: 15px;
  text-align: center;
}

.log {
  color: black;
  text-decoration: none;
}

.log:hover {
  color: blue;
  transition: all 0.2s ease-in-out;
}

.round {
  background-color: white;
  border: none;
  color: #48abe0;
  font-size: xx-large;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  box-shadow: 0 2px 4px darkslategray;
  margin-top: 120%;
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.round::after {
  content: "Home";
  display: none;
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translateX(30%);
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
}

.round:hover::after {
  display: block;
}
.loading-circle {
  width: 100px;
  height: 100px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack elements vertically */
  justify-content: center; /* Center elements vertically */
  align-items: center; /* Center elements horizontally */
  z-index: 9999; /* Ensure the overlay is on top of other elements */
}

.loading-text {
  margin-top: 20px;
  font-size: 20px;
  color: #3498db;
  text-align: center; /* Center the text */
}
