@import url(https://fonts.googleapis.com/css?family=Roboto:400,500);
@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);

*{
    margin: 0;
    padding: 0;
    font-family: Georgia, 'Times New Roman', Times, serif ;

}
.vd{
    padding-top: 50px;
    width: 100%;
    height: 50%;
    object-fit: contain;
}
.pic{
    float: right;
    border-radius: 5rem;
    width: 30%;
    height: 30%;
    margin: 0px 0px 20px 25px;
}
.sec{
    margin: 5%;
}
h3{
    color: green;
    text-align: center;
    font-size: 25px;
}
h2{
    text-align: center;
    padding: 10px;
    }
p{
 font-family: cursive;
 font-size: x-large;   
}
.pic1{
    float: left;
    border-radius: 5rem;
    width: 30%;
    height: 30%;
    margin: 0px 22px 20px 20px;
}
.pic2{
    float: right;
    border-radius: 5rem;
    width: 30%;
    height: 30%;
    margin: 0px 0px 20px 25px;
}
