* {
    padding: 0;
    margin: 0;
}

.otp-main-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0px 0px 60px rgba(8, 7, 16, 0.6);
    border-radius: 10px;
    background-color: white;
    text-align: center;
}

.reset-main-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0px 0px 60px rgba(8, 7, 16, 0.6);
    border-radius: 10px;
    background-color: white;
}

.otp-p{
    margin-top: 10px;
    font-size: large;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.email-v{
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: xx-large;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.verify_button {
    margin-top: 40px;
    background-color: #219ebc;
    padding: 15px;
    font-size: medium;
    font-weight: 600;
    border-radius: 5px;
    cursor: pointer;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
}

.verify_button:hover {
    background-color: #8ecae6;
}
.display-reset{
    display: flex;
    gap: 30px;
}
.form-reset{
    display: flex;
    flex-direction: column;
    gap: 20px;   

}
.form-reset label{
    text-align: end;
    font-size: x-large;
    padding-bottom: 7px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.form-reset input{
    font-size: x-large;
    border: 2px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px rgba(8, 7, 16, 0.6);
    border-radius: 5px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}
.center-reset{
    text-align: center;
}

.change-pos{
    text-align: center;
    padding-bottom: 10px;
}

.Otp-box {
    display: flex;
    margin-top: 20px;
    justify-content: center;
    gap: 20px;
}

.Otp-input {
    width: 60px;
    height: 60px;

}

.Otp-input input {
    width: 100%;
    height: 100%;
    font-size: xx-large;
    text-align: center;
    border-radius: 5px;
    border: 2px solid #219ebc;
    box-shadow: 0 0 10px rgba(34, 27, 88, 0.6);
}