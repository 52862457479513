* body{
    background: #EBF0F5; 
}

.body {
    text-align: center;
    padding: 100px 0;
    width: 100%;
}

.h-done {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}

.p-done{
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.checkmark{
    color: #9ABC66;
    font-size: 100px;
    line-height: 100px;
    margin-left: -15px;
}

.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}

.popup-goes {
    display: inline-block;
    background-color: #1e90ff;
    color: white;
    font-size: medium;
    text-decoration: none;
    padding: 15px;
    border-radius: 3px;
    margin-top: 40px;
    cursor: pointer;
    border: none;
}
